import { ApolloClient, createHttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import tp from '../../__generated/possible-types.json';
import { HEADER_LOCALE_NAME } from '../../constants';
import { getEnv } from '../lib/lang/env-variables/get-env.util';
import { isNode } from '../lib/lang/env-variables/is-node.util';

export const apolloCache = new InMemoryCache({
  possibleTypes: tp.possibleTypes,
  typePolicies: {
    ChallengePreset: {
      merge: true,
    },
    ChallengeByIdPayload: {
      merge: false,
    },
    Challenge: {
      merge: true,
    },

    // need to disable normalisation because we have cases when same object has different name but same id
    ChallengeMetricStrListAllowedItem: {
      keyFields: false,
    },
    ChallengeMetricStrAllowedItem: {
      keyFields: false,
    },
  },
});

export const createApolloClient = (locale: string): ApolloClient<NormalizedCacheObject> => {
  const httpLink = createHttpLink({
    uri: isNode ? getEnv('API_URL_INTERNAL') : getEnv('NEXT_PUBLIC_API_URL'),
    credentials: 'include',
  });
  const authLink = setContext((_, prevContext) => {
    return {
      headers: {
        ...prevContext.headers,
        [HEADER_LOCALE_NAME]: locale,
      },
    };
  });

  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache: apolloCache,
    name: 'wcsp-web-client',
  });
};
