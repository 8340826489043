import { Level, LoggerOptions } from 'pino';

const levelToSeverity: Record<string, string> = {
  trace: 'DEBUG',
  debug: 'DEBUG',
  info: 'INFO',
  warn: 'WARNING',
  error: 'ERROR',
  fatal: 'CRITICAL',
};

export interface ServiceContext {
  serviceName?: string;
  version?: string;
  mixin?: (mergeObject: object, level: number) => object;
}
// https://github.com/simenandre/pino-cloud-logging
export function gcpLogOptions(options?: LoggerOptions, context: ServiceContext = {}): LoggerOptions {
  const { mixin, serviceName, version } = context;

  const base = serviceName && version ? { serviceContext: { service: serviceName, version } } : {};

  return {
    base,
    formatters: {
      level(label: string): { severity: string } {
        const pinoLevel = label as Level;
        const severity = levelToSeverity[label] ?? 'INFO';
        // `@type` property tells Error Reporting to track even if there is no `stack_trace`
        // you might want to make this an option the plugin, in our case we do want error reporting for all errors, with or without a stack
        const typeProp =
          pinoLevel === 'error' || pinoLevel === 'fatal'
            ? {
                '@type': 'type.googleapis.com/google.devtools.clouderrorreporting.v1beta1.ReportedErrorEvent',
              }
            : {};
        return { severity, ...typeProp };
      },

      log(object): Record<string, any> {
        const logObject = object as { err?: Error };
        const stackTrace = logObject.err?.stack;
        const stackProp = stackTrace ? { stack_trace: stackTrace } : {};
        return { ...object, ...stackProp };
      },
    },
    mixin,
    messageKey: 'message',
    ...options,
  };
}
