import { env } from 'next-runtime-env';
import pino, { Logger as PinoLogger } from 'pino';

import { gcpLogOptions } from './gcp-logger-options';

export class Logger {
  private static instance: PinoLogger;

  private static getInstance(): PinoLogger {
    if (!Logger.instance) {
      Logger.instance = pino(
        gcpLogOptions({
          level: env('NEXT_PUBLIC_LOG_LEVEL') || 'info',
        })
      );
    }
    return Logger.instance;
  }

  // proxy methods

  public static debug(...args: any[]): void {
    return Logger.getInstance().debug(args);
  }

  public static info(...args: any[]): void {
    return Logger.getInstance().info(args);
  }

  public static warn(...args: any[]): void {
    return Logger.getInstance().warn(args);
  }

  public static error(...args: any[]): void {
    return Logger.getInstance().error(args);
  }
}
