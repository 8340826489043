import { env } from 'next-runtime-env';

export type EnvVariables =
  // runtime config
  | 'NEXT_PUBLIC_LOG_LEVEL'
  | 'NEXT_PUBLIC_LIVE_STREAM_PAGE_POLL_INTERVAL_IN_SECONDS'
  | 'NEXT_PUBLIC_NOTIFY_SURVEY_LINK_URL'

  // cdn config
  | 'NEXT_PUBLIC_CDN_URL'

  // next auth config
  | 'NEXTAUTH_URL'
  | 'NEXTAUTH_URL_INTERNAL'
  | 'NEXTAUTH_SECRET'
  | 'NEXTAUTH_COOKIE_NAME'

  // BE api config
  | 'NEXT_PUBLIC_API_URL'
  | 'API_URL_INTERNAL'

  // twitch oauth credentials
  | 'TWITCH_CLIENT_ID'
  | 'TWITCH_CLIENT_SECRET'

  // accounts db uri
  | 'DATABASE_URL'

  // hostname
  | 'NEXT_PUBLIC_HOST';

export const getEnv = (name: EnvVariables): string => {
  const value = env(name);
  if (!value) {
    console.error(`Missing required env variable: ${name}`);
  }
  return value || '';
};
