import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next-runtime-env/build/provider/env-provider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next-runtime-env/build/provider/use-env-context.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/src/global.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/shared/lib/fonts.ts\",\"import\":\"Inter\",\"arguments\":[{\"weight\":[\"400\",\"700\"],\"display\":\"swap\",\"variable\":\"--font-inter\",\"subsets\":[\"cyrillic-ext\",\"latin-ext\"]}],\"variableName\":\"interFont\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/shared/lib/fonts.ts\",\"import\":\"Dela_Gothic_One\",\"arguments\":[{\"weight\":[\"400\"],\"display\":\"swap\",\"variable\":\"--font-dela-gothic-one\",\"subsets\":[\"cyrillic\",\"latin-ext\"]}],\"variableName\":\"delaGothicOneFont\"}");
;
import(/* webpackMode: "eager", webpackExports: ["AppLayout"] */ "/app/src/layouts/app-layout/app-layout.tsx");
