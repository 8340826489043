'use client';
import * as React from 'react';

import { Nullable } from '../../types/lang.types';

export type GlobalVariables = {
  cfCountryCode: string | null;
};

export const GlobalVariablesContext = React.createContext<Nullable<GlobalVariables>>(null);
