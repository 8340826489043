'use client';
import { ApolloProvider } from '@apollo/client';
import { SessionProvider } from 'next-auth/react';
import { AbstractIntlMessages, NextIntlClientProvider } from 'next-intl';
import * as React from 'react';

import { createApolloClient } from '../../shared/api/apollo';
import { GlobalVariables } from '../../shared/lib/global-variables/global-variables.context';
import { GlobalVariablesProvider } from '../../shared/lib/global-variables/global-variables.provider';
import { Logger } from '../../shared/lib/logger/logger';

interface Props {
  children: React.ReactNode;
  locale: string;
  variables: GlobalVariables;
  messages: AbstractIntlMessages;
}

export const AppLayout: React.FC<Props> = props => {
  const { children, locale, variables, messages } = props;
  const apolloClient = createApolloClient(locale);
  return (
    <GlobalVariablesProvider variables={variables}>
      <NextIntlClientProvider locale={locale} messages={messages} onError={e => Logger.debug(e)}>
        <ApolloProvider client={apolloClient}>
          <SessionProvider>{children}</SessionProvider>
        </ApolloProvider>
      </NextIntlClientProvider>
    </GlobalVariablesProvider>
  );
};
