import React from 'react';

import { GlobalVariables, GlobalVariablesContext } from './global-variables.context';

interface Props {
  children: React.ReactNode;
  variables: GlobalVariables;
}
export const GlobalVariablesProvider = (props: Props): React.ReactElement => {
  return <GlobalVariablesContext.Provider value={props.variables}>{props.children}</GlobalVariablesContext.Provider>;
};
